<template>
	<div class="box">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/member/members_help' }">会员帮助列表</el-breadcrumb-item>
					<el-breadcrumb-item>帮助详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="help-detail" v-loading="loading">
				<div class="title">{{ detail.title }}</div>
				<div class="info">
					<div class="time">{{ $util.timeStampTurnTime(detail.create_time) }}</div>
				</div>
				<div class="content" v-html="detail.content"></div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import { helpDetail } from '@/api/cms/help';
	export default {
		name: 'members_help_detail',
		components: {},
		data: () => {
			return {
				detail: [],
				loading: true
			};
		},
		created() {
			this.getDetail();
		},
		methods: {
			getDetail() {
				helpDetail({
					id: this.$route.query.id || 0
				})
						.then(res => {
							if (res.code == 0) {
								if (res.data) {
									this.loading = false;
									this.detail = res.data;
								} else {
									this.$router.push({ path: '/member/members_help' });
								}
							}
						})
						.catch(err => {
							this.loading = false;
							this.$message.error(err.message);
						});
			}
		}
	};
</script>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}


	.help-detail {
		background-color: #ffffff;
		padding: 10px;
		border-radius: 5px;
		margin: 10px 0;
		.title {
			text-align: center;
			font-size: 18px;
			margin: 10px 0;
		}
		.info {
			margin: 0 43px;
			border-bottom: 1px dotted #e9e9e9;
			.time {
				text-align: center;
				color: #838383;
				margin-bottom: 17px;
			}
		}
		.content {
			padding-top: 10px;
			margin: 0 65px;
		}
	}
	.path {
		padding: 15px 0;
	}
</style>
